<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-user' }">
    <template #title>
      {{ translateTitleCase("entity.crud.createEntityTitle") }}
      <strong>{{ translateTitleCase("core.user.user") }}</strong>
    </template>

    <template #default>
      <crud-create
        :form-path="formPath"
        :form-action="formAction"
        :success-redirect="{ name: 'admin-user' }">
      </crud-create>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import CrudCreate from "@/client/components/crud/Create.vue";

import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n/index";

export default {
  name: "EntityCreate",
  components: { CrudCreate },
  props: {
    entityType: {
      type: String,
      default: "default",
    },
  },
  setup(props, setupContext) {
    let { t } = useI18n();
    let { meta } = useMeta({
      title: t("admin.user.createView.title"),
    });

    return { meta };
  },
  data: function () {
    return {
      entity: {},
    };
  },
  computed: {
    formPath() {
      return "form/user.user";
    },
    formAction() {
      return "user";
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
